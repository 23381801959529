export enum RoutesConfig {
  AUTH = 'auth',
  ATTENDANCE = 'attendance',
  SIGNATURE = 'signature',
  CHANGELOG = 'changelog',
  DASHBOARD = 'dashboard',
  CLINICS = 'clinics',
  CLINIC_SETTINGS = 'clinic-settings',
  CALENDAR = 'calendar',
  CUSTOMERS = 'customers',
  PATIENTS = 'patients',
  SEGMENTS = 'segments',
  RECORDS = 'medical-records',
  RECORD_TEMPLATES = 'record-templates',
  ALL_ITEMS = 'all-items',
  TANGIBLE = 'tangible',
  INTANGIBLE = 'intangible',
  BUNDLES = 'bundles',
  ROOMS = 'rooms',
  EQUIPMENT = 'equipment',
  STAFF = 'staff',
  SHIFT_PLANNER = 'shift-planner',
  TIME_TRACKER = 'time-tracker',
  INVOICES = 'invoices',
  DRAFTS = 'drafts',
  DRAFT_TEMPLATES = 'draft-templates',
  QUOTES = 'quotes',
  REPORTS = 'reports',
  WORK_TIME_REPORT = 'work-time',
  DOCUMENT_TEMPLATES = 'document-templates',
  EMAIL_TEMPLATES = 'email-templates',
  SELF_SERVICE = 'self-service',
  TEST_RESULT = 'test-result',
}
